define("@ember-data/legacy-compat/-private", ["exports", "@ember-data/legacy-compat/fetch-manager-9ea525ca"], function (_exports, _fetchManager9ea525ca) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "FetchManager", {
    enumerable: true,
    get: function () {
      return _fetchManager9ea525ca.F;
    }
  });
  Object.defineProperty(_exports, "SaveOp", {
    enumerable: true,
    get: function () {
      return _fetchManager9ea525ca.a;
    }
  });
  Object.defineProperty(_exports, "Snapshot", {
    enumerable: true,
    get: function () {
      return _fetchManager9ea525ca.b;
    }
  });
  Object.defineProperty(_exports, "SnapshotRecordArray", {
    enumerable: true,
    get: function () {
      return _fetchManager9ea525ca.S;
    }
  });
});