define("@ember-data/store/-private", ["exports", "@ember-data/store/index-8b77b852"], function (_exports, _index8b77b) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "AdapterPopulatedRecordArray", {
    enumerable: true,
    get: function () {
      return _index8b77b.f;
    }
  });
  Object.defineProperty(_exports, "CacheHandler", {
    enumerable: true,
    get: function () {
      return _index8b77b.C;
    }
  });
  Object.defineProperty(_exports, "IDENTIFIER_ARRAY_TAG", {
    enumerable: true,
    get: function () {
      return _index8b77b.j;
    }
  });
  Object.defineProperty(_exports, "IdentifierArray", {
    enumerable: true,
    get: function () {
      return _index8b77b.I;
    }
  });
  Object.defineProperty(_exports, "MUTATE", {
    enumerable: true,
    get: function () {
      return _index8b77b.M;
    }
  });
  Object.defineProperty(_exports, "RecordArray", {
    enumerable: true,
    get: function () {
      return _index8b77b.I;
    }
  });
  Object.defineProperty(_exports, "RecordArrayManager", {
    enumerable: true,
    get: function () {
      return _index8b77b.R;
    }
  });
  Object.defineProperty(_exports, "SOURCE", {
    enumerable: true,
    get: function () {
      return _index8b77b.h;
    }
  });
  Object.defineProperty(_exports, "Store", {
    enumerable: true,
    get: function () {
      return _index8b77b.S;
    }
  });
  Object.defineProperty(_exports, "_clearCaches", {
    enumerable: true,
    get: function () {
      return _index8b77b._;
    }
  });
  Object.defineProperty(_exports, "coerceId", {
    enumerable: true,
    get: function () {
      return _index8b77b.e;
    }
  });
  Object.defineProperty(_exports, "fastPush", {
    enumerable: true,
    get: function () {
      return _index8b77b.k;
    }
  });
  Object.defineProperty(_exports, "isStableIdentifier", {
    enumerable: true,
    get: function () {
      return _index8b77b.i;
    }
  });
  Object.defineProperty(_exports, "normalizeModelName", {
    enumerable: true,
    get: function () {
      return _index8b77b.n;
    }
  });
  Object.defineProperty(_exports, "notifyArray", {
    enumerable: true,
    get: function () {
      return _index8b77b.g;
    }
  });
  Object.defineProperty(_exports, "peekCache", {
    enumerable: true,
    get: function () {
      return _index8b77b.p;
    }
  });
  Object.defineProperty(_exports, "recordIdentifierFor", {
    enumerable: true,
    get: function () {
      return _index8b77b.r;
    }
  });
  Object.defineProperty(_exports, "removeRecordDataFor", {
    enumerable: true,
    get: function () {
      return _index8b77b.l;
    }
  });
  Object.defineProperty(_exports, "setIdentifierForgetMethod", {
    enumerable: true,
    get: function () {
      return _index8b77b.c;
    }
  });
  Object.defineProperty(_exports, "setIdentifierGenerationMethod", {
    enumerable: true,
    get: function () {
      return _index8b77b.a;
    }
  });
  Object.defineProperty(_exports, "setIdentifierResetMethod", {
    enumerable: true,
    get: function () {
      return _index8b77b.d;
    }
  });
  Object.defineProperty(_exports, "setIdentifierUpdateMethod", {
    enumerable: true,
    get: function () {
      return _index8b77b.b;
    }
  });
  Object.defineProperty(_exports, "storeFor", {
    enumerable: true,
    get: function () {
      return _index8b77b.s;
    }
  });
});