define("@ember-data/legacy-compat/index", ["exports", "@ember/debug", "@ember-data/legacy-compat/fetch-manager-9ea525ca"], function (_exports, _debug, _fetchManager9ea525ca) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LegacyNetworkHandler = void 0;
  function _findHasMany(adapter, store, identifier, link, relationship, options) {
    let promise = Promise.resolve().then(() => {
      const snapshot = store._fetchManager.createSnapshot(identifier, options);
      let useLink = !link || typeof link === 'string';
      let relatedLink = useLink ? link : link.href;
      return adapter.findHasMany(store, snapshot, relatedLink, relationship);
    });
    promise = (0, _fetchManager9ea525ca.g)(promise, store);
    promise = promise.then(adapterPayload => {
      const record = store._instanceCache.getRecord(identifier);
      if (!(0, _fetchManager9ea525ca._)(record)) {
        {
          (false && !(false) && (0, _debug.deprecate)(`A Promise for fetching ${relationship.type} did not resolve by the time your model was destroyed. This will error in a future release.`, false, {
            id: 'ember-data:rsvp-unresolved-async',
            until: '5.0',
            for: '@ember-data/store',
            since: {
              available: '4.5',
              enabled: '4.5'
            }
          }));
        }
      }
      (false && !((0, _fetchManager9ea525ca.p)(adapterPayload)) && (0, _debug.assert)(`You made a 'findHasMany' request for a ${identifier.type}'s '${relationship.key}' relationship, using link '${link}' , but the adapter's response did not have any data`, (0, _fetchManager9ea525ca.p)(adapterPayload)));
      const modelClass = store.modelFor(relationship.type);
      let serializer = store.serializerFor(relationship.type);
      let payload = (0, _fetchManager9ea525ca.n)(serializer, store, modelClass, adapterPayload, null, 'findHasMany');
      (false && !('data' in payload && Array.isArray(payload.data)) && (0, _debug.assert)(`fetched the hasMany relationship '${relationship.name}' for ${identifier.type}:${identifier.id} with link '${link}', but no data member is present in the response. If no data exists, the response should set { data: [] }`, 'data' in payload && Array.isArray(payload.data)));
      payload = syncRelationshipDataFromLink(store, payload, identifier, relationship);
      return store._push(payload, true);
    }, null, `DS: Extract payload of '${identifier.type}' : hasMany '${relationship.type}'`);
    {
      const record = store._instanceCache.getRecord(identifier);
      promise = (0, _fetchManager9ea525ca.c)(promise, (0, _fetchManager9ea525ca.d)(_fetchManager9ea525ca._, record));
    }
    return promise;
  }
  function _findBelongsTo(store, identifier, link, relationship, options) {
    let promise = Promise.resolve().then(() => {
      let adapter = store.adapterFor(identifier.type);
      (false && !(adapter) && (0, _debug.assert)(`You tried to load a belongsTo relationship but you have no adapter (for ${identifier.type})`, adapter));
      (false && !(typeof adapter.findBelongsTo === 'function') && (0, _debug.assert)(`You tried to load a belongsTo relationship from a specified 'link' in the original payload but your adapter does not implement 'findBelongsTo'`, typeof adapter.findBelongsTo === 'function'));
      let snapshot = store._fetchManager.createSnapshot(identifier, options);
      let useLink = !link || typeof link === 'string';
      let relatedLink = useLink ? link : link.href;
      return adapter.findBelongsTo(store, snapshot, relatedLink, relationship);
    });
    {
      const record = store._instanceCache.getRecord(identifier);
      promise = (0, _fetchManager9ea525ca.g)(promise, store);
      promise = (0, _fetchManager9ea525ca.c)(promise, (0, _fetchManager9ea525ca.d)(_fetchManager9ea525ca._, record));
    }
    promise = promise.then(adapterPayload => {
      {
        const record = store._instanceCache.getRecord(identifier);
        if (!(0, _fetchManager9ea525ca._)(record)) {
          (false && !(false) && (0, _debug.deprecate)(`A Promise for fetching ${relationship.type} did not resolve by the time your model was destroyed. This will error in a future release.`, false, {
            id: 'ember-data:rsvp-unresolved-async',
            until: '5.0',
            for: '@ember-data/store',
            since: {
              available: '4.5',
              enabled: '4.5'
            }
          }));
        }
      }
      let modelClass = store.modelFor(relationship.type);
      let serializer = store.serializerFor(relationship.type);
      let payload = (0, _fetchManager9ea525ca.n)(serializer, store, modelClass, adapterPayload, null, 'findBelongsTo');
      (false && !('data' in payload && (payload.data === null || typeof payload.data === 'object' && !Array.isArray(payload.data))) && (0, _debug.assert)(`fetched the belongsTo relationship '${relationship.name}' for ${identifier.type}:${identifier.id} with link '${link}', but no data member is present in the response. If no data exists, the response should set { data: null }`, 'data' in payload && (payload.data === null || typeof payload.data === 'object' && !Array.isArray(payload.data))));
      if (!payload.data && !payload.links && !payload.meta) {
        return null;
      }
      payload = syncRelationshipDataFromLink(store, payload, identifier, relationship);
      return store._push(payload, true);
    }, null, `DS: Extract payload of ${identifier.type} : ${relationship.type}`);
    return promise;
  }

  // sync
  // iterate over records in payload.data
  // for each record
  //   assert that record.relationships[inverse] is either undefined (so we can fix it)
  //     or provide a data: {id, type} that matches the record that requested it
  //   return the relationship data for the parent
  function syncRelationshipDataFromLink(store, payload, parentIdentifier, relationship) {
    // ensure the right hand side (incoming payload) points to the parent record that
    // requested this relationship
    let relationshipData = payload.data ? (0, _fetchManager9ea525ca.i)(payload.data, (data, index) => {
      const {
        id,
        type
      } = data;
      ensureRelationshipIsSetToParent(data, parentIdentifier, store, relationship, index);
      return {
        id,
        type
      };
    }) : null;
    const relatedDataHash = {};
    if ('meta' in payload) {
      relatedDataHash.meta = payload.meta;
    }
    if ('links' in payload) {
      relatedDataHash.links = payload.links;
    }
    if ('data' in payload) {
      relatedDataHash.data = relationshipData;
    }

    // now, push the left hand side (the parent record) to ensure things are in sync, since
    // the payload will be pushed with store._push
    const parentPayload = {
      id: parentIdentifier.id,
      type: parentIdentifier.type,
      relationships: {
        [relationship.key]: relatedDataHash
      }
    };
    if (!Array.isArray(payload.included)) {
      payload.included = [];
    }
    payload.included.push(parentPayload);
    return payload;
  }
  function ensureRelationshipIsSetToParent(payload, parentIdentifier, store, parentRelationship, index) {
    let {
      id,
      type
    } = payload;
    if (!payload.relationships) {
      payload.relationships = {};
    }
    let {
      relationships
    } = payload;
    let inverse = getInverse(store, parentIdentifier, parentRelationship, type);
    if (inverse) {
      let {
        inverseKey,
        kind
      } = inverse;
      let relationshipData = relationships[inverseKey] && relationships[inverseKey].data;
      if (kind !== 'hasMany' || typeof relationshipData !== 'undefined') {
        relationships[inverseKey] = relationships[inverseKey] || {};
        relationships[inverseKey].data = fixRelationshipData(relationshipData, kind, parentIdentifier);
      }
    }
  }
  function metaIsRelationshipDefinition(meta) {
    return typeof meta._inverseKey === 'function';
  }
  function inverseForRelationship(store, identifier, key) {
    const definition = store.getSchemaDefinitionService().relationshipsDefinitionFor(identifier)[key];
    if (!definition) {
      return null;
    }
    {
      if (metaIsRelationshipDefinition(definition)) {
        const modelClass = store.modelFor(identifier.type);
        return definition._inverseKey(store, modelClass);
      }
    }
    (false && !(definition.options?.inverse === null || typeof definition.options?.inverse === 'string' && definition.options.inverse.length > 0) && (0, _debug.assert)(`Expected the relationship defintion to specify the inverse type or null.`, definition.options?.inverse === null || typeof definition.options?.inverse === 'string' && definition.options.inverse.length > 0));
    return definition.options.inverse;
  }
  function getInverse(store, parentIdentifier, parentRelationship, type) {
    let {
      name: lhs_relationshipName
    } = parentRelationship;
    let {
      type: parentType
    } = parentIdentifier;
    let inverseKey = inverseForRelationship(store, {
      type: parentType
    }, lhs_relationshipName);
    if (inverseKey) {
      const definition = store.getSchemaDefinitionService().relationshipsDefinitionFor({
        type
      });
      let {
        kind
      } = definition[inverseKey];
      return {
        inverseKey,
        kind
      };
    }
  }
  function relationshipDataPointsToParent(relationshipData, identifier) {
    if (relationshipData === null) {
      return false;
    }
    if (Array.isArray(relationshipData)) {
      if (relationshipData.length === 0) {
        return false;
      }
      for (let i = 0; i < relationshipData.length; i++) {
        let entry = relationshipData[i];
        if (validateRelationshipEntry(entry, identifier)) {
          return true;
        }
      }
    } else {
      return validateRelationshipEntry(relationshipData, identifier);
    }
    return false;
  }
  function fixRelationshipData(relationshipData, relationshipKind, _ref) {
    let {
      id,
      type
    } = _ref;
    let parentRelationshipData = {
      id,
      type
    };
    let payload;
    if (relationshipKind === 'hasMany') {
      payload = relationshipData || [];
      if (relationshipData) {
        // these arrays could be massive so this is better than filter
        // Note: this is potentially problematic if type/id are not in the
        // same state of normalization.
        let found = relationshipData.find(v => {
          return v.type === parentRelationshipData.type && v.id === parentRelationshipData.id;
        });
        if (!found) {
          payload.push(parentRelationshipData);
        }
      } else {
        payload.push(parentRelationshipData);
      }
    } else {
      payload = relationshipData || {};
      Object.assign(payload, parentRelationshipData);
    }
    return payload;
  }
  function validateRelationshipEntry(_ref2, _ref3) {
    let {
      id
    } = _ref2;
    let {
      id: parentModelID
    } = _ref3;
    return id && id.toString() === parentModelID;
  }
  const PotentialLegacyOperations = new Set(['findRecord', 'findAll', 'query', 'queryRecord', 'findBelongsTo', 'findHasMany', 'updateRecord', 'createRecord', 'deleteRecord']);
  const LegacyNetworkHandler = {
    request(context, next) {
      // if we are not a legacy request, move on
      if (context.request.url || !context.request.op || !PotentialLegacyOperations.has(context.request.op)) {
        return next(context.request);
      }
      const {
        store
      } = context.request;
      if (!store._fetchManager) {
        store._fetchManager = new _fetchManager9ea525ca.F(store);
      }
      switch (context.request.op) {
        case 'findRecord':
          return findRecord(context);
        case 'findAll':
          return findAll(context);
        case 'query':
          return query(context);
        case 'queryRecord':
          return queryRecord(context);
        case 'findBelongsTo':
          return findBelongsTo(context);
        case 'findHasMany':
          return findHasMany(context);
        case 'updateRecord':
          return saveRecord(context);
        case 'createRecord':
          return saveRecord(context);
        case 'deleteRecord':
          return saveRecord(context);
        default:
          return next(context.request);
      }
    }
  };
  _exports.LegacyNetworkHandler = LegacyNetworkHandler;
  function findBelongsTo(context) {
    const {
      store,
      data,
      records: identifiers
    } = context.request;
    const {
      options,
      record,
      links,
      useLink,
      field
    } = data;
    const identifier = identifiers?.[0];

    // short circuit if we are already loading
    let pendingRequest = identifier && store._fetchManager.getPendingFetch(identifier, options);
    if (pendingRequest) {
      return pendingRequest;
    }
    if (useLink) {
      return _findBelongsTo(store, record, links.related, field, options);
    }
    (false && !(Array.isArray(identifiers) && identifiers.length === 1) && (0, _debug.assert)(`Expected an identifier`, Array.isArray(identifiers) && identifiers.length === 1));
    const manager = store._fetchManager;
    (0, _fetchManager9ea525ca.e)(identifier);
    return options.reload ? manager.scheduleFetch(identifier, options, context.request) : manager.fetchDataIfNeededForIdentifier(identifier, options, context.request);
  }
  function findHasMany(context) {
    const {
      store,
      data,
      records: identifiers
    } = context.request;
    const {
      options,
      record,
      links,
      useLink,
      field
    } = data;

    // link case
    if (useLink) {
      const adapter = store.adapterFor(record.type);
      /*
      If a relationship was originally populated by the adapter as a link
      (as opposed to a list of IDs), this method is called when the
      relationship is fetched.
       The link (which is usually a URL) is passed through unchanged, so the
      adapter can make whatever request it wants.
       The usual use-case is for the server to register a URL as a link, and
      then use that URL in the future to make a request for the relationship.
      */
      (false && !(adapter) && (0, _debug.assert)(`You tried to load a hasMany relationship but you have no adapter (for ${record.type})`, adapter));
      (false && !(typeof adapter.findHasMany === 'function') && (0, _debug.assert)(`You tried to load a hasMany relationship from a specified 'link' in the original payload but your adapter does not implement 'findHasMany'`, typeof adapter.findHasMany === 'function'));
      return _findHasMany(adapter, store, record, links.related, field, options);
    }

    // identifiers case

    const fetches = new Array(identifiers.length);
    const manager = store._fetchManager;
    for (let i = 0; i < identifiers.length; i++) {
      let identifier = identifiers[i];
      // TODO we probably can be lenient here and return from cache for the isNew case
      (0, _fetchManager9ea525ca.e)(identifier);
      fetches[i] = options.reload ? manager.scheduleFetch(identifier, options, context.request) : manager.fetchDataIfNeededForIdentifier(identifier, options, context.request);
    }
    return Promise.all(fetches);
  }
  function saveRecord(context) {
    const {
      store,
      data,
      op: operation
    } = context.request;
    const {
      options,
      record: identifier
    } = data;
    const saveOptions = Object.assign({
      [_fetchManager9ea525ca.a]: operation
    }, options);
    const fetchManagerPromise = store._fetchManager.scheduleSave(identifier, saveOptions);
    return fetchManagerPromise.then(payload => {
      /*
      // TODO @runspired re-evaluate the below claim now that
      // the save request pipeline is more streamlined.
       Note to future spelunkers hoping to optimize.
      We rely on this `run` to create a run loop if needed
      that `store._push` and `store.saveRecord` will both share.
       We use `join` because it is often the case that we
      have an outer run loop available still from the first
      call to `store._push`;
      */
      store._join(() => {
        let data = payload && payload.data;
        if (!data) {
          (false && !(identifier.id) && (0, _debug.assert)(`Your ${identifier.type} record was saved to the server, but the response does not have an id and no id has been set client side. Records must have ids. Please update the server response to provide an id in the response or generate the id on the client side either before saving the record or while normalizing the response.`, identifier.id));
        }
        const identifierCache = store.identifierCache;
        let actualIdentifier = identifier;
        if (operation !== 'deleteRecord' && data) {
          actualIdentifier = identifierCache.updateRecordIdentifier(identifier, data);
        }

        //We first make sure the primary data has been updated
        const cache = store._instanceCache.getResourceCache(actualIdentifier);
        cache.didCommit(identifier, data);
        if (payload && payload.included) {
          store._push({
            data: null,
            included: payload.included
          }, true);
        }
      });
      return store.peekRecord(identifier);
    }).catch(e => {
      let err = e;
      if (!e) {
        err = new Error(`Unknown Error Occurred During Request`);
      } else if (typeof e === 'string') {
        err = new Error(e);
      }
      adapterDidInvalidate(store, identifier, err);
      throw err;
    });
  }
  function adapterDidInvalidate(store, identifier, error) {
    if (error && error.isAdapterError === true && error.code === 'InvalidError') {
      let serializer = store.serializerFor(identifier.type);

      // TODO @deprecate extractErrors being called
      // TODO remove extractErrors from the default serializers.
      if (serializer && typeof serializer.extractErrors === 'function') {
        let errorsHash = serializer.extractErrors(store, store.modelFor(identifier.type), error, identifier.id);
        error.errors = errorsHashToArray(errorsHash);
      }
    }
    const cache = store._instanceCache.getResourceCache(identifier);
    if (error.errors) {
      (false && !(typeof cache.getErrors === 'function') && (0, _debug.assert)(`Expected the cache in use by resource ${String(identifier)} to have a getErrors(identifier) method for retreiving errors.`, typeof cache.getErrors === 'function'));
      let jsonApiErrors = error.errors;
      if (jsonApiErrors.length === 0) {
        jsonApiErrors = [{
          title: 'Invalid Error',
          detail: '',
          source: {
            pointer: '/data'
          }
        }];
      }
      cache.commitWasRejected(identifier, jsonApiErrors);
    } else {
      cache.commitWasRejected(identifier);
    }
  }
  function makeArray(value) {
    return Array.isArray(value) ? value : [value];
  }
  const PRIMARY_ATTRIBUTE_KEY = 'base';
  function errorsHashToArray(errors) {
    const out = [];
    if (errors) {
      Object.keys(errors).forEach(key => {
        let messages = makeArray(errors[key]);
        for (let i = 0; i < messages.length; i++) {
          let title = 'Invalid Attribute';
          let pointer = `/data/attributes/${key}`;
          if (key === PRIMARY_ATTRIBUTE_KEY) {
            title = 'Invalid Document';
            pointer = `/data`;
          }
          out.push({
            title: title,
            detail: messages[i],
            source: {
              pointer: pointer
            }
          });
        }
      });
    }
    return out;
  }
  function findRecord(context) {
    const {
      store,
      data
    } = context.request;
    const {
      record: identifier,
      options
    } = data;
    let promise;

    // if not loaded start loading
    if (!store._instanceCache.recordIsLoaded(identifier)) {
      promise = store._fetchManager.fetchDataIfNeededForIdentifier(identifier, options, context.request);

      // Refetch if the reload option is passed
    } else if (options.reload) {
      (0, _fetchManager9ea525ca.e)(identifier);
      promise = store._fetchManager.scheduleFetch(identifier, options, context.request);
    } else {
      let snapshot = null;
      let adapter = store.adapterFor(identifier.type);

      // Refetch the record if the adapter thinks the record is stale
      if (typeof options.reload === 'undefined' && adapter.shouldReloadRecord && adapter.shouldReloadRecord(store, snapshot = store._fetchManager.createSnapshot(identifier, options))) {
        (0, _fetchManager9ea525ca.e)(identifier);
        {
          options.reload = true;
          promise = store._fetchManager.scheduleFetch(identifier, options, context.request);
        }
      } else {
        // Trigger the background refetch if backgroundReload option is passed
        if (options.backgroundReload !== false && (options.backgroundReload || !adapter.shouldBackgroundReloadRecord || adapter.shouldBackgroundReloadRecord(store, snapshot = snapshot || store._fetchManager.createSnapshot(identifier, options)))) {
          (0, _fetchManager9ea525ca.e)(identifier);
          {
            options.backgroundReload = true;
            void store._fetchManager.scheduleFetch(identifier, options, context.request);
          }
        }

        // Return the cached record
        promise = Promise.resolve(identifier);
      }
    }
    return promise.then(identifier => store.peekRecord(identifier));
  }
  function findAll(context) {
    const {
      store,
      data
    } = context.request;
    const {
      type,
      options
    } = data;
    const adapter = store.adapterFor(type);
    (false && !(adapter) && (0, _debug.assert)(`You tried to load all records but you have no adapter (for ${type})`, adapter));
    (false && !(typeof adapter.findAll === 'function') && (0, _debug.assert)(`You tried to load all records but your adapter does not implement 'findAll'`, typeof adapter.findAll === 'function')); // avoid initializing the liveArray just to set `isUpdating`
    const maybeRecordArray = store.recordArrayManager._live.get(type);
    const snapshotArray = new _fetchManager9ea525ca.S(store, type, options);
    const shouldReload = options.reload || options.reload !== false && (adapter.shouldReloadAll && adapter.shouldReloadAll(store, snapshotArray) || !adapter.shouldReloadAll && snapshotArray.length === 0);
    let fetch;
    if (shouldReload) {
      maybeRecordArray && (maybeRecordArray.isUpdating = true);
      fetch = _findAll(adapter, store, type, snapshotArray, context.request, true);
    } else {
      fetch = Promise.resolve(store.peekAll(type));
      if (options.backgroundReload || options.backgroundReload !== false && (!adapter.shouldBackgroundReloadAll || adapter.shouldBackgroundReloadAll(store, snapshotArray))) {
        maybeRecordArray && (maybeRecordArray.isUpdating = true);
        void _findAll(adapter, store, type, snapshotArray, context.request, false);
      }
    }
    return fetch;
  }
  function _findAll(adapter, store, type, snapshotArray, request, isAsyncFlush) {
    const schema = store.modelFor(type);
    let promise = Promise.resolve().then(() => adapter.findAll(store, schema, null, snapshotArray));
    promise = (0, _fetchManager9ea525ca.g)(promise, store);
    promise = promise.then(adapterPayload => {
      (false && !((0, _fetchManager9ea525ca.p)(adapterPayload)) && (0, _debug.assert)(`You made a 'findAll' request for '${type}' records, but the adapter's response did not have any data`, (0, _fetchManager9ea525ca.p)(adapterPayload)));
      const serializer = store.serializerFor(type);
      const payload = (0, _fetchManager9ea525ca.n)(serializer, store, schema, adapterPayload, null, 'findAll');
      store._push(payload, isAsyncFlush);
      snapshotArray._recordArray.isUpdating = false;
      return snapshotArray._recordArray;
    });
    return promise;
  }
  function query(context) {
    const {
      store,
      data
    } = context.request;
    let {
      options
    } = data;
    const {
      type,
      query
    } = data;
    const adapter = store.adapterFor(type);
    (false && !(adapter) && (0, _debug.assert)(`You tried to make a query but you have no adapter (for ${type})`, adapter));
    (false && !(typeof adapter.query === 'function') && (0, _debug.assert)(`You tried to make a query but your adapter does not implement 'query'`, typeof adapter.query === 'function'));
    const recordArray = options._recordArray || store.recordArrayManager.createArray({
      type,
      query
    });
    {
      delete options._recordArray;
    }
    const schema = store.modelFor(type);
    let promise = Promise.resolve().then(() => adapter.query(store, schema, query, recordArray, options));
    promise = (0, _fetchManager9ea525ca.g)(promise, store);
    return promise.then(adapterPayload => {
      const serializer = store.serializerFor(type);
      const payload = (0, _fetchManager9ea525ca.n)(serializer, store, schema, adapterPayload, null, 'query');
      const identifiers = store._push(payload, true);
      (false && !(Array.isArray(identifiers)) && (0, _debug.assert)('The response to store.query is expected to be an array but it was a single record. Please wrap your response in an array or use `store.queryRecord` to query for a single record.', Array.isArray(identifiers)));
      store.recordArrayManager.populateManagedArray(recordArray, identifiers, payload);
      return recordArray;
    });
  }
  function assertSingleResourceDocument(payload) {
    (false && !(!Array.isArray(payload.data)) && (0, _debug.assert)(`Expected the primary data returned by the serializer for a 'queryRecord' response to be a single object or null but instead it was an array.`, !Array.isArray(payload.data)));
  }
  function queryRecord(context) {
    const {
      store,
      data
    } = context.request;
    const {
      type,
      query,
      options
    } = data;
    const adapter = store.adapterFor(type);
    (false && !(adapter) && (0, _debug.assert)(`You tried to make a query but you have no adapter (for ${type})`, adapter));
    (false && !(typeof adapter.queryRecord === 'function') && (0, _debug.assert)(`You tried to make a query but your adapter does not implement 'queryRecord'`, typeof adapter.queryRecord === 'function'));
    const schema = store.modelFor(type);
    let promise = Promise.resolve().then(() => adapter.queryRecord(store, schema, query, options));
    promise = (0, _fetchManager9ea525ca.g)(promise, store);
    return promise.then(adapterPayload => {
      const serializer = store.serializerFor(type);
      const payload = (0, _fetchManager9ea525ca.n)(serializer, store, schema, adapterPayload, null, 'queryRecord');
      assertSingleResourceDocument(payload);
      const identifier = store._push(payload, true);
      return identifier ? store.peekRecord(identifier) : null;
    });
  }
});